import React from 'react'
import Masonry from 'react-masonry-component'
import MasonryImage from '../../components/MasonryImage'

const masonryOptions = {
  transitionDuration: 0,
  layoutMode: 'masonry',
  itemSelector: '.grid-item',
  columnWidth: '.grid-item',
  percentPosition: true,
  gutter: 28
};

class Gallery extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      width: 0
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions.bind(this))
  }
  
  updateWindowDimensions = () => {
    this.setState({ 
      width: this.masonry.containerWidth
    })
  }

  render() {
    return (
      <Masonry
        className='grid'
        options={masonryOptions}
        ref={(c) => this.masonry = this.masonry || c.masonry}
      >
        {this.props.images && this.props.images.length ? (
          <div>
          {this.props.images.map((image, i) => 
            <MasonryImage 
              key={`${i}-image`}
              windowWidth={this.state.width}
              image={image.image}
              isFirst={i === 0}
            />
          )}
          </div>
        ) : null}
      </Masonry>
    );
  }
}

export default Gallery
