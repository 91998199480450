import React from 'react'

const Meta = (props) => (
  <>
    <div id="project_meta">
      <span className="project-title">{props.title} {props.year}</span>
      <span className="project-location">at {props.location}</span>
    </div>
    
    <div id="description">
      <div className="quote-inner">
        {props.description}
      </div>
    </div>
  </>
)

export default Meta
