import React from 'react'
import PropTypes from 'prop-types'
import Meta from './Meta'
import Gallery from './Gallery'

export const ProjectPostTemplate = ({
  description,
  tags,
  title,
  year,
  location,
  helmet,
  images
}) => {
  return (
    <div className="container content">
      <Meta 
        title={title}
        year={year}
        location={location}
        description={description}
      />
      
      <Gallery 
        images={images}
      />
    </div>
  )
}

ProjectPostTemplate.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  year: PropTypes.number,
  location: PropTypes.string,
  helmet: PropTypes.object,
}

export default ProjectPostTemplate
